<template>
  <div class="input-group-append form-group">

    <input name="name" id="namea" type="text" class="form-control rounded-left col-6" placeholder="Enter keywords :"
      v-model="search_line_string" />

    <select class="form-control custom-select rounded-0 col-2" v-model="whereToSearch_Selected">
      <option v-bind:value="x" v-for="x in whereToSearchFilter_Options">{{ x.value }}</option>
    </select>
    <select class="form-control custom-select rounded-0 col-2" v-model="andOrFilter_Selected">
      <option v-bind:value="x" v-for="x in andOrFilter_Options">{{ x.value }}</option>
    </select>
    <!-- <a href="#" class="text-dark col-1" @click="deleteItem">X</a> -->
    <i class="uil uil-multiply ml-3 mt-2" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"
      @click="deleteItem"></i>
  </div>
</template>

<script>
export default {
  name: "SearchLine",
  props: {
    search_parameters_arr: {
      type: Array,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

  },
  data() {
    return {

      hover: false,


      search_line_string: '',
      andOrFilter_Options: [
        { id: 1, value: 'Все слова' },
        { id: 2, value: 'Любое из слов' },
      ],
      andOrFilter_Selected: { id: 1, value: 'Все слова' },

      whereToSearchFilter_Options: [
        { id: 1, value: 'Fulltext search' },
        { id: 2, value: 'В текстах' },
        { id: 3, value: 'В авторах' },
        { id: 4, value: 'Город' },
      ],
      whereToSearch_Selected: { id: 1, value: 'Fulltext search' },


      search_parameters_obj: {
        index: 1,
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      },
    }
  },

  watch: {
    whereToSearch_Selected: function (val) {
      this.search_parameters_obj.whereToSearchFilter_id = val.id;
      this.search_parameters_obj.index = this.index;
      /*
      console.log('inside watch whereToSearch_Selected: ' + val);

      let temp_arr = this.search_parameters_arr;
      temp_arr.splice(this.index+1,1,this.search_parameters_obj);

       */
      this.$emit('search_parameters_arr', this.search_parameters_obj);
    },
    andOrFilter_Selected: function (val) {
      //console.log('inside watch andOrFilter_Selected: ' + val);
      this.search_parameters_obj.andOrFilter_id = val.id;
      this.search_parameters_obj.index = this.index;
      this.$emit('search_parameters_arr', this.search_parameters_obj);
    },
    search_line_string: function (val) {
      //console.log('inside watch search_line_string: ' + val);
      //console.log(this.search_parameters_arr);
      this.search_parameters_obj.search_line_string = val;
      this.search_parameters_obj.index = this.index;
      this.$emit('search_parameters_arr', this.search_parameters_obj);
    }
  },
  methods: {
    deleteItem() {
      console.log('inside delete item')
      this.$emit('index', this.index);
    }
  },

  mounted() {
    this.search_line_string = this.search_parameters_arr[this.index + 1].search_line_string;
    this.whereToSearch_Selected = this.whereToSearchFilter_Options.filter(x => x.id === this.search_parameters_arr[this.index + 1].whereToSearchFilter_id)[0]
    this.andOrFilter_Selected = this.andOrFilter_Options.filter(x => x.id === this.search_parameters_arr[this.index + 1].andOrFilter_id)[0]

  }
}
</script>

<style scoped></style>