<script>
import { ArrowUpIcon, MapPinIcon, PlusIcon, MoreHorizontalIcon } from "vue-feather-icons";


import SearchLine from "./SearchLine";
import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {

    //Navbar,
    //Switcher,
    //Footer,
    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MoreHorizontalIcon,
    SearchLine,
  },
  data() {
    return {

      hover: false,

      additionalParamentersMenu: false,

      search_line_string: '',
      andOrFilter_Options: [
        { id: 1, value: 'Все слова' },
        { id: 2, value: 'Любое из слов' },
      ],
      andOrFilter_Selected: { id: 1, value: 'Все слова' },

      whereToSearchFilter_Options: [
        { id: 1, value: 'Fulltext search' },
        { id: 2, value: 'В текстах' },
        { id: 3, value: 'В авторах' },
        { id: 4, value: 'Город' },
      ],
      whereToSearch_Selected: { id: 1, value: 'Fulltext search' },


      additionalSearchLines: [1, 2],
      advancedSearchSwitch: true,



      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
      },

      searchLine: '',

      search_parameters: [
        {
          leftParethesis: false,
          field: { name: 'Resume headline', id: 1, type: 'String' },
          relationship: { name: 'contains', id: 2 },
          value: '',
          orButton: false,
          andButton: false,
          rightParethesis: false,
          id: 0
        }
      ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      },
      search_parameters_additional: [],



      setSQL_ids: {
        ids_arr: [1, 2],
        save_search_id: 1,
        user_id: 2,
      },
      totalSearchResults: 1,
      savedSearch: { name: 'Базовый', id: 0, type_id: 0 },

      resumeIds: [],
      resumes: [],

      total_resume: 358386,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],
      latestSearches: [],

      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id: 0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },


      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr: [],


    };
  },
  watch: {
    whereToSearch_Selected(val) {
      this.search_parameters_arr[0].whereToSearchFilter_id = val.id;
    },
    andOrFilter_Selected(val) {
      this.search_parameters_arr[0].andOrFilter_id = val.id;
    },
    search_line_string(val) {
      this.search_parameters_arr[0].search_line_string = val;
    }
  },
  mounted() {


  },

  methods: {

    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = { id: 1, value: 'Все слова' };
      this.whereToSearch_Selected = { id: 1, value: 'Fulltext search' };
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional = [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },
    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index + 1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index + 1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index + 1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },
    /*
        updateResume(e) {
          console.log('inside updateResume')
          console.log(e);
    
        },
        */

    updateResume(e) {
      console.log('inside updateResume')
      console.log(e);

    },


    searchPosts() {

      this.resumeIds = [];
      this.resumes = [];


      this.input_advanced_search.searchParameters = this.search_parameters_arr;


      console.log('inside searchPosts');
      console.log(this.input_advanced_search);

      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',

        url: '' + this.$hostnamejava + '/search-ba/get-hash',
        data: this.input_advanced_search,
      }).then((res) => {
        //        console.log(res.data);
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (res.data.status === 'ok') {

          let route = this.$router.resolve({ path: '/search-ba/' + res.data.hash + '/page/1' });
          window.open(route.href, '_self');
        }

      }).catch((err) => {
        console.error(err);
        //loading.close();
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },



    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active + 1, this.page.max);
      this.page.down = Math.max(this.page.active - 1, this.page.min);
      if (this.page.active > this.page.min) {
        this.page_arr.push({ page: '' + this.page.min, active: 'notactive' });
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active - this.page.min >= this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.down, active: 'notactive' });
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }
      } else {

        for (var i = 2; i < this.page.active; i++) {
          this.page_arr.push({ page: '' + i, active: 'notactive' });
        }
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }

      }
      if (this.page.max - this.page.active >= this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({ page: '' + this.page.up, active: 'notactive' });
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.max, active: 'notactive' });

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max === i & this.page.active === this.page.max) {
            this.page_arr.push({ page: '' + i, active: 'active' });
          } else {
            this.page_arr.push({ page: '' + i, active: 'notactive' });
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x) {

      if (x === -1) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.down });
        window.open(route.href, '_self');
      } else if (x === -2) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.up });
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + x });
        window.open(route.href, '_self');
      }

    },




  },


};
</script>

<template>
  <div>
    <!-- Search Start -->
    <section class="section-two bg-light">
      <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <form class="p-4 shadow bg-white rounded">
              <div class="d-flex justify-content-between">
                <h4 class="mb-3">Поиск по базе Brand Analytics</h4>
                <!--
                <div>
                  <a class="btn btn-outline-light btn-sm"
                     v-b-modal.HistoricSearches
                     data-toggle="modal"
                     data-target="#HistoricSearches"> Предыдущие поиски </a>
                  <b-modal id="HistoricSearches" title="Предыдущие поиски" centered>
                    <div class="table-responsive bg-white shadow">
                      <table class="table table-center table-padding mb-0">
                        <thead>
                        <tr>

                          <th class="text-left py-3" >Ключевые слова</th>
                          <th class="text-left py-3" >Дата</th>

                          <th class="text-left py-3" ></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(item, ix) of latestSearches" :key="ix">
                          <td class="text-left" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="$router.push({path:'/search-candidates/'+item.hash+'/page/1'})">
                            {{item.name}}</td>
                          <td class="text-left"> {{item.date}}</td>
                          <td class="h6"><a @click="removeSearch(ix)"
                                            @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                                            class="text-danger">X</a></td>
                        </tr>

                        </tbody>
                      </table>
                    </div>

                  </b-modal>

                  <a @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     @click="nullSearch" class="btn btn-outline-light btn-sm ml-1"> Сбросить </a>
                </div>
                -->


              </div>
              <div class="row">
                <div class="col-10">

                  <div class="input-group-append form-group">





                    <input name="name" id="name" type="text" class="form-control rounded-left col-6"
                      placeholder="Enter keywords :" v-model="search_line_string" />

                    <select class="form-control custom-select rounded-0" v-model="whereToSearch_Selected">
                      <option v-bind:value="x" v-for="x in whereToSearchFilter_Options">{{ x.value }}</option>
                    </select>
                    <select class="form-control custom-select rounded-0" v-model="andOrFilter_Selected">
                      <option v-bind:value="x" v-for="x in andOrFilter_Options">{{ x.value }}</option>
                    </select>



                    <input class="searchbtn btn btn-primary btn-block" value="Поиск" @click="searchPosts" />
                  </div>
                  <SearchLine v-for="(line, ix) in search_parameters_additional" :index="ix" @index="updateParameters"
                    :search_parameters_arr="search_parameters_arr"
                    @search_parameters_arr="update_search_parameters_arr" />
                </div>
                <div class="col-2">
                  <a class="btn btn-icon btn-md btn-outline-info btn-pills mr-1" @click="addRemoveLine(-1)"><plus-icon
                      class="fea icon-md"></plus-icon></a>


                </div>
                <!-- </div> -->
              </div>

            </form>
            <!--end form-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>








    <!--
       <Footer />

       <Switcher />
       -->

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
